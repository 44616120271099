import Nav from './components/nav';
import Fot from './components/footer';
import d1 from './assets/ourteam/d1.webp';
import d2 from './assets/ourteam/d2.webp';
import d3 from './assets/ourteam/d3.webp';
import d4 from './assets/ourteam/d4.webp';
import o1 from './assets/ourteam/o1.webp';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect, useRef } from 'react';

export default function OurTeam() {
    const { t } = useTranslation();
    const [lightModeScreen, setLightModeScreen] = useState(true);

    useEffect(() => {
        lightModeScreenManager();
        const intervalId = setInterval(lightModeScreenManager, 100);
        return () => {
            clearInterval(intervalId);
        };
    }, []);

    function lightModeScreenManager() {
        let savedValueForMode = localStorage.getItem('Mode');
        if (savedValueForMode == null) {
            setLightModeScreen(true);
            localStorage.setItem('Mode', true);
        } else {
            setLightModeScreen(savedValueForMode === 'true');
        }
    }
    return (
        <>
            <Nav />
            <div className='ourteam-whole-page' style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
                <div className='doctors-container' style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>

                    <div className="profile-ourteam">
                        <img src={o1} alt="Doctor's Image" id="docImage-ourteam" style={{ width: '250px', height: '350px', marginLeft: '25px', marginRight: '25px' }} />
                        <div className="info-ourteam">
                            <h2 style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("ourteamtext1doctor4")}</h2>
                            <p style={{ color: lightModeScreen ? 'black' : '#dbd7d7' }}>{t("ourteamtext2doctor4")}</p>
                            <br />
                            <p style={{ color: lightModeScreen ? 'black' : '#dbd7d7' }}>{t("ourteamtext3doctor4")}</p>
                            <br />
                            <p style={{ color: lightModeScreen ? 'black' : '#dbd7d7' }}>{t("ourteamtext6doctor4")}</p>
                        </div>
                    </div>

                    <div className="profile-ourteam">
                        <img src={d2} alt="Doctor's Image" id="docImage-ourteam" style={{ width: '250px', height: '300px', marginLeft: '25px', marginRight: '25px' }} />
                        <div className="info-ourteam">
                            <h2 style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("ourteamtext1doctor2")}</h2>
                            <p style={{ color: lightModeScreen ? 'black' : '#dbd7d7' }}>{t("ourteamtext2doctor2")}</p>
                            <br />
                            <p style={{ color: lightModeScreen ? 'black' : '#dbd7d7' }}>{t("ourteamtext3doctor2")}</p>
                            <br />
                            <p style={{ color: lightModeScreen ? 'black' : '#dbd7d7' }}>{t("ourteamtext4doctor2")}</p>
                            <br />
                            <p style={{ color: lightModeScreen ? 'black' : '#dbd7d7' }}>{t("ourteamtext6doctor2")}</p>
                        </div>
                    </div>

                    <div className="profile-ourteam">
                        <img src={d3} alt="Doctor's Image" id="docImage-ourteam" style={{ width: '250px', height: '300px', marginLeft: '25px', marginRight: '25px' }} />
                        <div className="info-ourteam">
                            <h2 style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("ourteamtext1doctor3")}</h2>
                            <p style={{ color: lightModeScreen ? 'black' : '#dbd7d7' }}>{t("ourteamtext2doctor3")}</p>
                            <p style={{ color: lightModeScreen ? 'black' : '#dbd7d7' }}>{t("ourteamtext3doctor3")}</p>
                            <br />
                            <p style={{ color: lightModeScreen ? 'black' : '#dbd7d7' }}>{t("ourteamtext4doctor3")}</p>
                            <ul>
                                <li style={{ color: lightModeScreen ? 'black' : '#dbd7d7' }}>{t("ourteamtext5doctor3")}</li>
                                <li style={{ color: lightModeScreen ? 'black' : '#dbd7d7' }}>{t("ourteamtext6doctor3")}</li>
                                <li style={{ color: lightModeScreen ? 'black' : '#dbd7d7' }}>{t("ourteamtext7doctor3")}</li>
                                <li style={{ color: lightModeScreen ? 'black' : '#dbd7d7' }}>{t("ourteamtext8doctor3")}</li>
                                <li style={{ color: lightModeScreen ? 'black' : '#dbd7d7' }}>{t("ourteamtext9doctor3")}</li>
                                <li style={{ color: lightModeScreen ? 'black' : '#dbd7d7' }}>{t("ourteamtext10doctor3")}</li>
                                <li style={{ color: lightModeScreen ? 'black' : '#dbd7d7' }}>{t("ourteamtext11doctor3")}</li>
                            </ul>
                            <br />
                            <p style={{ color: lightModeScreen ? 'black' : '#dbd7d7' }}>{t("ourteamtext12doctor3")}</p>
                        </div>
                    </div>
                    <div className="profile-ourteam">
                        <img src={d4} alt="Doctor's Image" id="docImage-ourteam" style={{ width: '250px', height: '300px', marginLeft: '25px', marginRight: '25px' }} />
                        <div className="info-ourteam">
                            <h2 style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("ourteamtext1doctor5")}</h2>
                            <p style={{ color: lightModeScreen ? 'black' : '#dbd7d7', marginBottom: '20px' }}>{t("ourteamtext2doctor5")}</p>
                            <p style={{ color: lightModeScreen ? 'black' : '#dbd7d7' }}>{t("ourteamtext3doctor5")}</p>
                            <br />
                            <p style={{ color: lightModeScreen ? 'black' : '#dbd7d7' }}>{t("ourteamtext4doctor5")}</p>
                            <br />
                            <p style={{ color: lightModeScreen ? 'black' : '#dbd7d7' }}>{t("ourteamtext12doctor5")}</p>
                        </div>
                    </div>
                </div>
            </div>
            <Fot />
        </>
    );
}


