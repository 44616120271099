import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Admin from './admin/main';
import Clients from './clients';
import Login from './login';
import Home from './home';
import Services from './services';
import Ultrasound from './ultrasound';
import Mri from './mri';
import Enjoymri from './enjomriexp';
import Ctscan from './ctscan';
import Contactus from './contactus';
import DigitalMammogram from './digitalmomo';
import DigitalXray from './digitalxray';
import DintalImagining from './dentalimagining';
import Brainhead from './brainhead';
import Chestimag from './chestimag';
import Breastimag from './breastimag';
import Abdomenimag from './abdomenimag';
import Appointments from './Appointments';
import ManageAppointments from './admin/manageappointments';
import Dentalimag from './dentalimag';
import Prostateimag from './prostateimag';
import Maskuloskelettalimag from './maskulul';
import Vascularimag from './vaskulul';
import Accounts from './admin/accounts';
import OurNews from './ournews';
import Gellary from './gellary';
import Aboutus from './aboutus';
import Blocksipmanager from './admin/blockipsmanager';
import Ourteam from './ourteam';
import AdminRouteGuard from './AdminRouteGuard';
import UserRouteGuard from './UserRouteGuard';
import DoctorRouteGuard from './DoctorRouteGuard';
import ClientServices from './client/clientservices';
import ClientServicesList from './doctors/clientserviceslist';
import Doctors from './doctors';
import Log from './admin/log';
import Logo from './assets/logo2.webp';

export default function App() {

    const getCookie = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    };

    return (
        <Router>
            <div>
                <Routes>
                    <Route path="/" element={<HomeWithHelmet />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/services" element={<ServicesWithHelmet />} />
                    <Route path="/ourteam" element={<OurTeamWithHelmet />} />
                    <Route path="/contactus" element={<Contactus />} />
                    <Route path="/appointments" element={<AppointmentsWithHelmet />} />
                    <Route path="/ourNews" element={<OurNewsWithHelmet />} />
                    <Route path="/gallery" element={<GellaryWithHelmet />} />
                    <Route path="/aboutus" element={<AboutusWithHelmet />} />

                    <Route path="/ultrasound" element={<UltrasoundWithHelmet />} />
                    <Route path="/mri" element={<MriWithHelmet />} />
                    <Route path="/enjoymriexperience" element={<EnjoymriWithHelmet />} />
                    <Route path="/ctscan" element={<CtscanWithHelmet />} />
                    <Route path="/digitalmammogram" element={<DigitalMammogramWithHelmet />} />
                    <Route path="/digitalxray" element={<DigitalXrayWithHelmet />} />
                    <Route path="/dentalimagining" element={<DintalImaginingWithHelmet />} />
                    <Route path="/brainhead" element={<BrainheadWithHelmet />} />
                    <Route path="/chestimaging" element={<ChestimagWithHelmet />} />
                    <Route path="/breastimaging" element={<BreastimagWithHelmet />} />
                    <Route path="/abdomenimaging" element={<AbdomenimagWithHelmet />} />
                    <Route path="/dentalimaging" element={<DentalimagWithHelmet />} />
                    <Route path="/prostateimaging" element={<ProstateimagWithHelmet />} />
                    <Route path="/maskuloskelettalimaging" element={<MaskuloskelettalimagWithHelmet />} />
                    <Route path="/vascularimaging" element={<VascularimagWithHelmet />} />

                    <Route path="/log" element={<AdminRouteGuard><Log /></AdminRouteGuard>} />
                    <Route path="/manageappointments" element={<AdminRouteGuard><ManageAppointments /></AdminRouteGuard>} />
                    <Route path="/admin" element={<AdminRouteGuard><Admin /></AdminRouteGuard>} />
                    <Route path="/clients" element={<UserRouteGuard><Clients /></UserRouteGuard>} />
                    <Route path="/clientsservices" element={<UserRouteGuard><ClientServices /></UserRouteGuard>} />
                    <Route path="/doctors" element={<DoctorRouteGuard><Doctors /></DoctorRouteGuard>} />
                    <Route path="/patentserviceslist" element={<DoctorRouteGuard><ClientServicesList /></DoctorRouteGuard>} />
                    <Route path="/accounts" element={<AdminRouteGuard><Accounts /></AdminRouteGuard>} />
                    <Route path="/blockipsmanager" element={<AdminRouteGuard><Blocksipmanager /></AdminRouteGuard>} />
                </Routes>
            </div>
        </Router>
    );
}

const WebsiteSchema = () => (
    <Helmet>
        <script type="application/ld+json">
            {`
                {
                    "@context": "http://schema.org",
                    "@type": "WebSite",
                    "name": "Resonance Diagnostic Imaging Center",
                    "url": "https://ridc.ae"
                }
            `}
        </script>
        <meta name="author" content="Osman amin Daboul" />
        <meta name="creator" content="Osman amin Daboul" />
        <meta name="contributor" content="Osman amin Daboul" />
        <meta name="publisher" content="Resonance Diagnostic Imaging Center" />
    </Helmet>
);

function HomeWithHelmet() {
    return (
        <>
            <Helmet>
                <title>RIDC - Resonance Diagnostic Imaging Center | Medical Imaging in Dubai</title>
                <meta name="description" content="Visit Resonance Diagnostic Imaging Center (RIDC) in Dubai for advanced MRI, CT Scan, and Ultrasound services. Accurate diagnoses and compassionate care for your health." />
                <meta property="og:title" content="RIDC - Resonance Diagnostic Imaging Center | Medical Imaging in Dubai" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="Visit Resonance Diagnostic Imaging Center (RIDC) in Dubai for advanced MRI, CT Scan, and Ultrasound services. Accurate diagnoses and compassionate care for your health." />
                <meta property="og:image" content={Logo} />
                <meta property="og:url" content="https://ridc.ae" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="keywords" content="RIDC, Resonance Diagnostic Imaging Center, Medical Imaging, Dubai, MRI, Full MRI Body, CT Scan, Ultrasound" />
                <link rel="canonical" href="https://ridc.ae/" />
                <meta name="robots" content="index, follow" />
            </Helmet>
            <WebsiteSchema />
            <Home />
        </>
    );
}

function ServicesWithHelmet() {
    return (
        <>
            <Helmet>
                <title>Services - RIDC | Medical Imaging Services in Dubai - MRI, CT Scan, Ultrasound</title>
                <meta name="description" content="Discover Resonance's range of medical imaging services, including ultrasound, MRI, CT scan, digital mammography, dental imaging, digital x-ray, and more. Our state-of-the-art facilities and experienced professionals ensure accurate and timely diagnoses in Dubai." />
                <meta property="og:title" content="Services - RIDC | Medical Imaging Services in Dubai - MRI, CT Scan, Ultrasound" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="Discover Resonance's range of medical imaging services, including ultrasound, MRI, CT scan, digital mammography, dental imaging, digital x-ray, and more. Our state-of-the-art facilities and experienced professionals ensure accurate and timely diagnoses in Dubai." />
                <meta property="og:image" content={Logo} />
                <meta property="og:url" content="https://ridc.ae/services" />
                <meta name="twitter:card" content="summary_large_image" />
                <link rel="canonical" href="https://ridc.ae/services" />
                <meta
                    name="keywords"
                    content="Services, RIDC, Resonance Diagnostic Imaging Center, Medical Imaging, MRI, CT Scan, Ultrasound, Diagnostic Services, Dubai"
                />
            </Helmet>
            <WebsiteSchema />
            <Services />
        </>
    );
}

function OurTeamWithHelmet() {
    return (
        <>
            <Helmet>
                <title>Our Team at RIDC | Resonance Diagnostic Imaging Center in Dubai</title>
                <meta name="description" content="Meet the experts at RIDC, Resonance Diagnostic Imaging Center in Dubai. Our team consists of experienced radiologists and medical professionals dedicated to providing the best diagnostic services." />
                <meta property="og:title" content="Our Team at RIDC | Resonance Diagnostic Imaging Center in Dubai" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="Meet the experts at RIDC, Resonance Diagnostic Imaging Center in Dubai. Our team consists of experienced radiologists and medical professionals dedicated to providing the best diagnostic services." />
                <meta property="og:image" content={Logo} />
                <meta property="og:url" content="https://ridc.ae/ourteam" />
                <meta name="twitter:card" content="summary_large_image" />
                <link rel="canonical" href="https://ridc.ae/ourteam" />
                <meta 
                    name="keywords" 
                    content="Our Team, RIDC, Resonance Diagnostic Imaging Center, Radiologists, Medical Professionals, Diagnostic Imaging, Dubai" 
                />
            </Helmet>
            <WebsiteSchema />
            <Ourteam />
        </>
    );
}

function OurNewsWithHelmet() {
    return (
        <>
            <Helmet>
                <title>Our News Page</title>
                <meta name="description" content="Stay updated with the latest news and updates from Resonance Diagnostic Imaging Center. Explore our news section for articles, announcements, and insights on medical imaging advancements, patient stories, healthcare trends, and more. Discover how Resonance is making a difference in Dubai's healthcare landscape." />
                <meta property="og:title" content="Resonance Diagnostic Imaging Center News" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="Stay updated with the latest news and updates from Resonance Diagnostic Imaging Center. Explore our news section for articles, announcements, and insights on medical imaging advancements, patient stories, healthcare trends, and more. Discover how Resonance is making a difference in Dubai's healthcare landscape." />
                <meta property="og:image" content={Logo} />
                <meta property="og:url" content="https://ridc.ae/ournews" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <WebsiteSchema />
            <OurNews />
        </>
    );
}

function GellaryWithHelmet() {
    return (
        <>
            <Helmet>
                <title>Our Gellary Page</title>
                <meta name="description" content="Explore the Gallery of Resonance Diagnostic Imaging Center and view a curated collection of images showcasing our state-of-the-art facilities, advanced medical imaging technologies, and patient care environment in Dubai. Browse through our gallery to get a glimpse of our commitment to excellence in healthcare and patient satisfaction." />
                <meta property="og:title" content="Resonance Diagnostic Imaging Center Gellary" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="Explore the Gallery of Resonance Diagnostic Imaging Center and view a curated collection of images showcasing our state-of-the-art facilities, advanced medical imaging technologies, and patient care environment in Dubai. Browse through our gallery to get a glimpse of our commitment to excellence in healthcare and patient satisfaction." />
                <meta property="og:image" content={Logo} />
                <meta property="og:url" content="https://ridc.ae/gellary" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <WebsiteSchema />
            <Gellary />
        </>
    );
}

function AboutusWithHelmet() {
    return (
        <>
            <Helmet>
                <title>About Us - RIDC | Resonance Diagnostic Imaging Center in Dubai</title>
                <meta name="description" content="Learn about RIDC, Resonance Diagnostic Imaging Center in Dubai. Discover our mission, advanced medical imaging services, and commitment to accurate diagnoses and compassionate care." />
                <meta property="og:title" content="About Us - RIDC | Resonance Diagnostic Imaging Center in Dubai" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="Learn about RIDC, Resonance Diagnostic Imaging Center in Dubai. Discover our mission, advanced medical imaging services, and commitment to accurate diagnoses and compassionate care." />
                <meta property="og:image" content={Logo} />
                <meta property="og:url" content="https://ridc.ae/aboutus" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta
                    name="keywords"
                    content="About RIDC, Resonance Diagnostic Imaging Center, Medical Imaging, Dubai, Advanced Diagnostics, MRI, CT Scan, Ultrasound"
                />
                <link rel="canonical" href="https://ridc.ae/aboutus" />
                <meta name="robots" content="index, follow" />
            </Helmet>
            <WebsiteSchema />
            <Aboutus />
        </>
    );
}

function AppointmentsWithHelmet() {
    return (
        <>
            <Helmet>
                <title>Appointments Page</title>
                <meta name="description" content="Schedule your appointment at Resonance Diagnostic Imaging Center in Dubai conveniently online. Our booking platform allows you to choose your preferred date and time for medical imaging services, including ultrasound, MRI, CT scan, digital mammography, and more. Book your appointment hassle-free and ensure timely access to our state-of-the-art facilities and experienced professionals." />
                <meta property="og:title" content="Resonance Diagnostic Imaging Center Appointments" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="Schedule your appointment at Resonance Diagnostic Imaging Center in Dubai conveniently online. Our booking platform allows you to choose your preferred date and time for medical imaging services, including ultrasound, MRI, CT scan, digital mammography, and more. Book your appointment hassle-free and ensure timely access to our state-of-the-art facilities and experienced professionals." />
                <meta property="og:image" content={Logo} />
                <meta property="og:url" content="https://ridc.ae/appointments" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <WebsiteSchema />
            <Appointments />
        </>
    );
}

//Services

function UltrasoundWithHelmet() {
    return (
        <>
            <Helmet>
                <title>Ultrasound Services at RIDC | Resonance Diagnostic Imaging Center in Dubai</title>
                <meta name="description" content="Explore ultrasound services at RIDC, Resonance Diagnostic Imaging Center in Dubai. Our advanced ultrasound technology provides accurate diagnostics for various medical conditions including pregnancy, tumors, and more." />
                <meta property="og:title" content="Ultrasound Services at RIDC | Resonance Diagnostic Imaging Center in Dubai" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="Explore ultrasound services at RIDC, Resonance Diagnostic Imaging Center in Dubai. Our advanced ultrasound technology provides accurate diagnostics for various medical conditions including pregnancy, tumors, and more." />
                <meta property="og:image" content={Logo} />
                <meta property="og:url" content="https://ridc.ae/ultrasound" />
                <meta name="twitter:card" content="summary_large_image" />
                <link rel="canonical" href="https://ridc.ae/ultrasound" />
                <meta 
                    name="keywords" 
                    content="Ultrasound, RIDC, Resonance Diagnostic Imaging Center, Medical Imaging, Ultrasound Services, Ultrasound Technology, Pregnancy Ultrasound, Dubai" 
                />
            </Helmet>
            <WebsiteSchema />
            <Ultrasound />
        </>
    );
}

function MriWithHelmet() {
    return (
        <>
            <Helmet>
                <title>MRI Services at RIDC | Resonance Diagnostic Imaging Center in Dubai</title>
                <meta name="description" content="Explore MRI services at RIDC, Resonance Diagnostic Imaging Center in Dubai. Our advanced MRI technology provides accurate diagnostics for various medical conditions." />
                <meta property="og:title" content="MRI Services at RIDC | Resonance Diagnostic Imaging Center in Dubai" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="Explore MRI services at RIDC, Resonance Diagnostic Imaging Center in Dubai. Our advanced MRI technology provides accurate diagnostics for various medical conditions." />
                <meta property="og:image" content={Logo} />
                <meta property="og:url" content="https://ridc.ae/mri" />
                <meta name="twitter:card" content="summary_large_image" />
                <link rel="canonical" href="https://ridc.ae/mri" />
                <meta 
                    name="keywords" 
                    content="MRI, RIDC, Resonance Diagnostic Imaging Center, Medical Imaging, MRI Services, MRI Technology, Dubai" 
                />
            </Helmet>
            <WebsiteSchema />
            <Mri />
        </>
    );
}

function EnjoymriWithHelmet() {
    return (
        <>
            <Helmet>
                <title>Enjoy MRI Experience at RIDC | Resonance Diagnostic Imaging Center in Dubai</title>
                <meta name="description" content="Experience the future of MRI technology at RIDC in Dubai. Our Enjoy MRI experience combines advanced imaging with cinematic comfort for an innovative diagnostic experience." />
                <meta property="og:title" content="Enjoy MRI Experience at RIDC | Resonance Diagnostic Imaging Center in Dubai" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="Experience the future of MRI technology at RIDC in Dubai. Our Enjoy MRI experience combines advanced imaging with cinematic comfort for an innovative diagnostic experience." />
                <meta property="og:image" content={Logo} />
                <meta property="og:url" content="https://ridc.ae/enjoymriexperience" />
                <meta name="twitter:card" content="summary_large_image" />
                <link rel="canonical" href="https://ridc.ae/enjoymriexperience" />
                <meta 
                    name="keywords" 
                    content="Enjoy MRI, RIDC, Resonance Diagnostic Imaging Center, MRI, Advanced MRI, MRI Experience, Diagnostic Imaging, Dubai" 
                />
            </Helmet>
            <WebsiteSchema />
            <Enjoymri />
        </>
    );
}

function CtscanWithHelmet() {
    return (
        <>
            <Helmet>
                <title>CT Scan Services at RIDC | Resonance Diagnostic Imaging Center in Dubai</title>
                <meta name="description" content="Explore CT scan services at RIDC, Resonance Diagnostic Imaging Center in Dubai. Our advanced CT scanning technology provides accurate diagnostics for various medical conditions, ensuring high-quality results." />
                <meta property="og:title" content="CT Scan Services at RIDC | Resonance Diagnostic Imaging Center in Dubai" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="Explore CT scan services at RIDC, Resonance Diagnostic Imaging Center in Dubai. Our advanced CT scanning technology provides accurate diagnostics for various medical conditions, ensuring high-quality results." />
                <meta property="og:image" content={Logo} />
                <meta property="og:url" content="https://ridc.ae/ctscan" />
                <meta name="twitter:card" content="summary_large_image" />
                <link rel="canonical" href="https://ridc.ae/ctscan" />
                <meta 
                    name="keywords" 
                    content="CT Scan, RIDC, Resonance Diagnostic Imaging Center, Medical Imaging, CT Scanning, CT Scan Technology, Diagnostics, Dubai" 
                />
            </Helmet>
            <WebsiteSchema />
            <Ctscan />
        </>
    );
}

function DigitalMammogramWithHelmet() {
    return (
        <>
            <Helmet>
                <title>Digital Mammogram Services at RIDC | Resonance Diagnostic Imaging Center in Dubai</title>
                <meta name="description" content="Explore digital mammogram services at RIDC, Resonance Diagnostic Imaging Center in Dubai. Our advanced digital mammography technology ensures accurate breast cancer detection and diagnostics." />
                <meta property="og:title" content="Digital Mammogram Services at RIDC | Resonance Diagnostic Imaging Center in Dubai" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="Explore digital mammogram services at RIDC, Resonance Diagnostic Imaging Center in Dubai. Our advanced digital mammography technology ensures accurate breast cancer detection and diagnostics." />
                <meta property="og:image" content={Logo} />
                <meta property="og:url" content="https://ridc.ae/digitalmammogram" />
                <meta name="twitter:card" content="summary_large_image" />
                <link rel="canonical" href="https://ridc.ae/digitalmammogram" />
                <meta 
                    name="keywords" 
                    content="Digital Mammogram, RIDC, Resonance Diagnostic Imaging Center, Medical Imaging, Mammography, Breast Cancer Detection, Dubai" 
                />
            </Helmet>
            <WebsiteSchema />
            <DigitalMammogram />
        </>
    );
}

function DigitalXrayWithHelmet() {
    return (
        <>
            <Helmet>
                <title>Digital X-ray Services at RIDC | Resonance Diagnostic Imaging Center in Dubai</title>
                <meta name="description" content="Explore digital X-ray services at RIDC, Resonance Diagnostic Imaging Center in Dubai. Our advanced digital X-ray technology provides accurate diagnostics for bone fractures, joint injuries, and more." />
                <meta property="og:title" content="Digital X-ray Services at RIDC | Resonance Diagnostic Imaging Center in Dubai" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="Explore digital X-ray services at RIDC, Resonance Diagnostic Imaging Center in Dubai. Our advanced digital X-ray technology provides accurate diagnostics for bone fractures, joint injuries, and more." />
                <meta property="og:image" content={Logo} />
                <meta property="og:url" content="https://ridc.ae/digitalxray" />
                <meta name="twitter:card" content="summary_large_image" />
                <link rel="canonical" href="https://ridc.ae/digitalxray" />
                <meta 
                    name="keywords" 
                    content="Digital X-ray, RIDC, Resonance Diagnostic Imaging Center, Medical Imaging, X-ray Services, Digital X-ray Technology, Dubai" 
                />
            </Helmet>
            <WebsiteSchema />
            <DigitalXray />
        </>
    );
}

function DintalImaginingWithHelmet() {
    return (
        <>
            <Helmet>
                <title>Dintal Imagining Page</title>
                <meta name="description" content="Explore the dental imaging services offered at Resonance Diagnostic Imaging Center in Dubai. Our advanced dental imaging technology provides detailed images for diagnosing dental conditions and planning treatments such as root canals, dental implants, and orthodontic procedures. Learn about our state-of-the-art equipment, experienced dental radiographers, and commitment to providing comprehensive dental care." />
                <meta property="og:title" content="Resonance Diagnostic Imaging Center Dintal Imagining" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="Explore the dental imaging services offered at Resonance Diagnostic Imaging Center in Dubai. Our advanced dental imaging technology provides detailed images for diagnosing dental conditions and planning treatments such as root canals, dental implants, and orthodontic procedures. Learn about our state-of-the-art equipment, experienced dental radiographers, and commitment to providing comprehensive dental care." />
                <meta property="og:image" content={Logo} />
                <meta property="og:url" content="https://ridc.ae/dentalimagining" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <WebsiteSchema />
            <DintalImagining />
        </>
    );
}

function BrainheadWithHelmet() {
    return (
        <>
            <Helmet>
                <title>Brain and Head Imaging Services at RIDC | Resonance Diagnostic Imaging Center in Dubai</title>
                <meta name="description" content="Explore brain and head imaging services at RIDC, Resonance Diagnostic Imaging Center in Dubai. Our advanced imaging technology provides accurate diagnostics for conditions like brain tumors, neurological disorders, and more." />
                <meta property="og:title" content="Brain and Head Imaging Services at RIDC | Resonance Diagnostic Imaging Center in Dubai" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="Explore brain and head imaging services at RIDC, Resonance Diagnostic Imaging Center in Dubai. Our advanced imaging technology provides accurate diagnostics for conditions like brain tumors, neurological disorders, and more." />
                <meta property="og:image" content={Logo} />
                <meta property="og:url" content="https://ridc.ae/brainhead" />
                <meta name="twitter:card" content="summary_large_image" />
                <link rel="canonical" href="https://ridc.ae/brainhead" />
                <meta 
                    name="keywords" 
                    content="Brain Imaging, Head Imaging, Brain Tumors, Neurological Disorders, RIDC, Resonance Diagnostic Imaging Center, MRI, Diagnostic Imaging, Dubai" 
                />
            </Helmet>
            <WebsiteSchema />
            <Brainhead />
        </>
    );
}

function ChestimagWithHelmet() {
    return (
        <>
            <Helmet>
                <title>Chest Imaging Services at RIDC | Resonance Diagnostic Imaging Center in Dubai</title>
                <meta name="description" content="Explore chest imaging services at RIDC, Resonance Diagnostic Imaging Center in Dubai. Our advanced chest imaging technology provides accurate diagnostics for conditions like lung diseases, pneumonia, and more." />
                <meta property="og:title" content="Chest Imaging Services at RIDC | Resonance Diagnostic Imaging Center in Dubai" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="Explore chest imaging services at RIDC, Resonance Diagnostic Imaging Center in Dubai. Our advanced chest imaging technology provides accurate diagnostics for conditions like lung diseases, pneumonia, and more." />
                <meta property="og:image" content={Logo} />
                <meta property="og:url" content="https://ridc.ae/chestimaging" />
                <meta name="twitter:card" content="summary_large_image" />
                <link rel="canonical" href="https://ridc.ae/chestimaging" />
                <meta 
                    name="keywords" 
                    content="Chest Imaging, RIDC, Resonance Diagnostic Imaging Center, Lung Diseases, Pneumonia, Chest X-ray, Diagnostic Imaging, Dubai" 
                />
            </Helmet>
            <WebsiteSchema />
            <Chestimag />
        </>
    );
}


function BreastimagWithHelmet() {
    return (
        <>
            <Helmet>
                <title>Breast Imaging Page</title>
                <meta name="description" content="Discover the breast imaging services offered at Resonance Diagnostic Imaging Center in Dubai. Our specialized breast imaging technology provides detailed and high-resolution images for the early detection and diagnosis of breast cancer and other breast abnormalities. Learn about our state-of-the-art equipment, experienced technologists, and commitment to providing compassionate and comprehensive breast care." />
                <meta property="og:title" content="Resonance Diagnostic Imaging Center Breast Imaging" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="Discover the breast imaging services offered at Resonance Diagnostic Imaging Center in Dubai. Our specialized breast imaging technology provides detailed and high-resolution images for the early detection and diagnosis of breast cancer and other breast abnormalities. Learn about our state-of-the-art equipment, experienced technologists, and commitment to providing compassionate and comprehensive breast care." />
                <meta property="og:image" content={Logo} />
                <meta property="og:url" content="https://ridc.ae/breastimaging" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <WebsiteSchema />
            <Breastimag />
        </>
    );
}
function AbdomenimagWithHelmet() {
    return (
        <>
            <Helmet>
                <title>Abdomen Imaging Services at RIDC | Resonance Diagnostic Imaging Center in Dubai</title>
                <meta name="description" content="Explore abdomen imaging services at RIDC, Resonance Diagnostic Imaging Center in Dubai. Our advanced imaging technology helps diagnose conditions such as abdominal pain, organ issues, and more." />
                <meta property="og:title" content="Abdomen Imaging Services at RIDC | Resonance Diagnostic Imaging Center in Dubai" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="Explore abdomen imaging services at RIDC, Resonance Diagnostic Imaging Center in Dubai. Our advanced imaging technology helps diagnose conditions such as abdominal pain, organ issues, and more." />
                <meta property="og:image" content={Logo} />
                <meta property="og:url" content="https://ridc.ae/abdomenimaging" />
                <meta name="twitter:card" content="summary_large_image" />
                <link rel="canonical" href="https://ridc.ae/abdomenimaging" />
                <meta 
                    name="keywords" 
                    content="Abdomen Imaging, RIDC, Resonance Diagnostic Imaging Center, Abdominal Pain, Abdominal Imaging, Medical Imaging, Diagnostic Imaging, Dubai" 
                />
            </Helmet>
            <WebsiteSchema />
            <Abdomenimag />
        </>
    );
}

function DentalimagWithHelmet() {
    return (
        <>
            <Helmet>
                <title>Dental Imaging Services at RIDC | Resonance Diagnostic Imaging Center in Dubai</title>
                <meta name="description" content="Explore dental imaging services at RIDC, Resonance Diagnostic Imaging Center in Dubai. Our advanced dental imaging technology provides accurate diagnostics for dental health and treatment planning." />
                <meta property="og:title" content="Dental Imaging Services at RIDC | Resonance Diagnostic Imaging Center in Dubai" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="Explore dental imaging services at RIDC, Resonance Diagnostic Imaging Center in Dubai. Our advanced dental imaging technology provides accurate diagnostics for dental health and treatment planning." />
                <meta property="og:image" content={Logo} />
                <meta property="og:url" content="https://ridc.ae/dentalimaging" />
                <meta name="twitter:card" content="summary_large_image" />
                <link rel="canonical" href="https://ridc.ae/dentalimagining" />
                <meta 
                    name="keywords" 
                    content="Dental Imaging, RIDC, Resonance Diagnostic Imaging Center, Medical Imaging, Dental Diagnostics, Dental X-ray, Dubai" 
                />
            </Helmet>
            <WebsiteSchema />
            <Dentalimag />
        </>
    );
}

function ProstateimagWithHelmet() {
    return (
        <>
            <Helmet>
                <title>Prostate Imaging Services at RIDC | Resonance Diagnostic Imaging Center in Dubai</title>
                <meta name="description" content="Explore prostate imaging services at RIDC, Resonance Diagnostic Imaging Center in Dubai. Our advanced imaging technology helps diagnose conditions like prostate cancer, benign prostate enlargement, and other related issues." />
                <meta property="og:title" content="Prostate Imaging Services at RIDC | Resonance Diagnostic Imaging Center in Dubai" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="Explore prostate imaging services at RIDC, Resonance Diagnostic Imaging Center in Dubai. Our advanced imaging technology helps diagnose conditions like prostate cancer, benign prostate enlargement, and other related issues." />
                <meta property="og:image" content={Logo} />
                <meta property="og:url" content="https://ridc.ae/prostateimaging" />
                <meta name="twitter:card" content="summary_large_image" />
                <link rel="canonical" href="https://ridc.ae/prostateimaging" />
                <meta 
                    name="keywords" 
                    content="Prostate Imaging, RIDC, Resonance Diagnostic Imaging Center, Prostate Cancer, Benign Prostate Enlargement, Medical Imaging, Diagnostic Imaging, Dubai" 
                />
            </Helmet>
            <WebsiteSchema />
            <Prostateimag />
        </>
    );
}

function MaskuloskelettalimagWithHelmet() {
    return (
        <>
            <Helmet>
                <title>Musculoskeletal Imaging Services at RIDC | Resonance Diagnostic Imaging Center in Dubai</title>
                <meta name="description" content="Explore musculoskeletal imaging services at RIDC, Resonance Diagnostic Imaging Center in Dubai. Our advanced imaging technology helps diagnose conditions like joint injuries, fractures, and other musculoskeletal disorders." />
                <meta property="og:title" content="Musculoskeletal Imaging Services at RIDC | Resonance Diagnostic Imaging Center in Dubai" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="Explore musculoskeletal imaging services at RIDC, Resonance Diagnostic Imaging Center in Dubai. Our advanced imaging technology helps diagnose conditions like joint injuries, fractures, and other musculoskeletal disorders." />
                <meta property="og:image" content={Logo} />
                <meta property="og:url" content="https://ridc.ae/maskuloskelettalimaging" />
                <meta name="twitter:card" content="summary_large_image" />
                <link rel="canonical" href="https://ridc.ae/maskuloskelettalimaging" />
                <meta 
                    name="keywords" 
                    content="Musculoskeletal Imaging, Joint Imaging, RIDC, Resonance Diagnostic Imaging Center, Medical Imaging, Musculoskeletal Disorders, Dubai" 
                />
            </Helmet>
            <WebsiteSchema />
            <Maskuloskelettalimag />
        </>
    );
}

function VascularimagWithHelmet() {
    return (
        <>
            <Helmet>
                <title>Vascular Imaging Services at RIDC | Resonance Diagnostic Imaging Center in Dubai</title>
                <meta name="description" content="Explore vascular imaging services at RIDC, Resonance Diagnostic Imaging Center in Dubai. Our advanced imaging technology helps diagnose vascular conditions such as arterial blockages, aneurysms, and other circulatory system disorders." />
                <meta property="og:title" content="Vascular Imaging Services at RIDC | Resonance Diagnostic Imaging Center in Dubai" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="Explore vascular imaging services at RIDC, Resonance Diagnostic Imaging Center in Dubai. Our advanced imaging technology helps diagnose vascular conditions such as arterial blockages, aneurysms, and other circulatory system disorders." />
                <meta property="og:image" content={Logo} />
                <meta property="og:url" content="https://ridc.ae/vascularimaging" />
                <meta name="twitter:card" content="summary_large_image" />
                <link rel="canonical" href="https://ridc.ae/services/vascularimaging" />
                <meta 
                    name="keywords" 
                    content="Vascular Imaging, RIDC, Resonance Diagnostic Imaging Center, Arterial Blockages, Aneurysms, Circulatory System Disorders, Diagnostic Imaging, Dubai" 
                />
            </Helmet>
            <WebsiteSchema />
            <Vascularimag />
        </>
    );
}